<template>
  <div
    v-if="
      $store.state.fastPermissoes.administrador == 'S' &&
        $store.state.fastPermissoes.ativo == 'S'
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Financeiro</a>
            </li>
            <li
              v-b-hover="handleHover"
              class="fd-app-breadcrumb-item"
            >
              <b-icon
                v-if="isHovered"
                v-b-toggle.sidebar-1
                style="cursor: pointer"
                icon="question-circle-fill"
                variant="danger"
                scale="1.5"
              />
              <b-icon
                v-else
                v-b-toggle.sidebar-1
                style="cursor: pointer"
                icon="question-circle"
                variant="secundary"
              />
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <b-sidebar
        id="sidebar-1"
        title="OnBoarding"
        shadow
      >
        <div class="px-3 py-2">
          <p>
            Explicação sobre o financeiro Gerencianet, como emitir carnês para
            seus alunos.
          </p>
          <div>
            <iframe
              width="100%"
              src="https://www.youtube.com/embed/VRFh_TZ1Xwo"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
      </b-sidebar>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Gerenciamento
                  <span id="txtDashboardNomePlataforma">Financeiro</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12">
                  <tabs :options="{ useUrlFragment: false }">
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Promoções"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                          <h4 class="aluno_font_color">
                            Lista de promoções
                          </h4>
                        </div>
                        <div
                          class="col-sm-12 col-md-12 col-lg-8 mt-4 mb-1 text-right"
                        >
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="showModal('modalCriarLojaPromo')"
                          >
                            <small>+ Adicionar promoção</small>
                          </button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                              <tr>
                                <th class="text-center">
                                  <small class="font-weight-bold">#</small>
                                </th>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Nome do produto</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Valor à vista</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Valor parcelado</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Limite de parcelas</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Cursos</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Link</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastLojaPromo.length">
                              <tr
                                v-for="(promo, index) in fastLojaPromo"
                                :key="promo.id_promo"
                              >
                                <td class="text-center align-middle">
                                  {{ index + 1 }}
                                </td>
                                <td class="align-middle">
                                  <small>{{ promo.nome_promo }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small>R$
                                    {{ promo.valor_avista.toFixed(2) }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small>R$
                                    {{
                                      promo.valor_parcelado.toFixed(2)
                                    }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small>{{ promo.limite_parcelas }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <button
                                    class="btn btn-sm btn-success pt-0 pb-0"
                                    @click="exibeModalCursosPromo(promo)"
                                  >
                                    <small>Exibir cursos
                                      <span class="badge badge-light">{{
                                        promo.qtde_cursos
                                      }}</span></small>
                                  </button>
                                </td>
                                <td class="text-center align-middle">
                                  <a
                                    :href="promo.url"
                                    target="_blank"
                                  >Acessar</a>
                                </td>
                                <td class="text-center align-middle">
                                  <button
                                    class="btn btn-sm btn-primary mr-1 pt-0 pb-0"
                                    @click="exibeModalEditarPromo(promo)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-danger mr-1 pt-0 pb-0"
                                    @click="exibeModalRemoverPromo(promo)"
                                  >
                                    <small>Excluir</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </tab>
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Contas a pagar"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                          <h4 class="aluno_font_color">
                            Contas a pagar
                          </h4>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                              <tr>
                                <th>
                                  <small class="font-weight-bold">#</small>
                                </th>
                                <th>
                                  <small class="font-weight-bold">Plano</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Valor</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Emissão</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Vencimento</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Status</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastPlataformaFaturas.length">
                              <tr
                                v-for="fatura in fastPlataformaFaturas"
                                :key="fatura.id"
                              >
                                <td class="align-middle">
                                  <small>{{ fatura.id }}</small>
                                </td>
                                <td class="align-middle">
                                  <small v-if="fatura.subscription">{{
                                    fatura.subscription.plan.name
                                  }}</small>
                                  <small
                                    v-else
                                    class="text-danger"
                                  >Serviço Customizado</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small>R$
                                    {{
                                      somaProdutosFatura(fatura.bill_items)
                                    }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small>{{
                                    new Date(fatura.created_at)
                                      .toLocaleString()
                                      .split(" ")[0]
                                  }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small v-if="fatura.due_at">{{
                                    new Date(fatura.due_at)
                                      .toLocaleString()
                                      .split(" ")[0]
                                  }}</small>
                                </td>

                                <td class="text-center align-middle">
                                  <a
                                    v-if="fatura.status == 'paid'"
                                    target="_blank"
                                    :href="fatura.url"
                                    class="btn btn-sm btn-success mr-2"
                                  >
                                    <small>PAGO (VER)</small>
                                  </a>
                                  <a
                                    v-else-if="fatura.status == 'pending'"
                                    target="_blank"
                                    :href="fatura.url"
                                    class="btn btn-sm btn-primary mr-2"
                                  >
                                    <small>PAGAR</small>
                                  </a>
                                  <a
                                    v-else-if="fatura.status == 'scheduled'"
                                    target="_blank"
                                    :href="fatura.url"
                                    class="btn btn-sm btn-primary mr-2"
                                  >
                                    <small>PROGRAMADA</small>
                                  </a>
                                  <span
                                    v-else-if="fatura.status == 'canceled'"
                                    class="btn btn-sm btn-danger mr-2"
                                  >
                                    <small>CANCELADA</small>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </tab>
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Contas a receber"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                          <h4 class="aluno_font_color">
                            Contas a receber
                          </h4>
                        </div>
                      </div>

                      <div
                        v-if="!fastGateway"
                        class="row"
                      >
                        <div class="col-12 table-responsive mt-2">
                          <div class="row">
                            <div class="col-6 table-responsive mt-2">
                              <h5>
                                Gateway de pagamento:
                                <img
                                  v-lazy="imagefastEad"
                                  rel="Gerencianet"
                                  class="img-fluid"
                                  style="max-height: 15px"
                                >
                              </h5>
                            </div>
                            <!-- <div class="col-6 table-responsive mt-2">
                              <h5>Nome:</h5>
                            </div>-->
                          </div>
                          <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                              <tr>
                                <th>
                                  <small class="font-weight-bold">Aluno</small>
                                </th>
                                <th>
                                  <small class="font-weight-bold">Email</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Plano</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Valor venda</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Tx. Adm.</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Valor repasse</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Período</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Cliente status</small>
                                </th>
                              </tr>
                            </thead>

                            <tbody v-if="fastUsuarios.length">
                              <tr
                                v-for="usuario in fastUsuarios"
                                :key="usuario.id_usuario"
                              >
                                <td class="align-middle">
                                  <small v-if="usuario.faturas.length">{{
                                    usuario.faturas[0].customer.name
                                  }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small v-if="usuario.faturas.length">{{
                                    usuario.faturas[0].customer.email
                                  }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small v-if="usuario.faturas.length">{{
                                    usuario.faturas[0].bill_items[0].product
                                      .name
                                  }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small
                                    v-if="usuario.faturas.length"
                                  >R$
                                    {{
                                      usuario.faturas[0].amount.replace(
                                        ".",
                                        ","
                                      )
                                    }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small
                                    v-if="usuario.faturas.length"
                                  >R$
                                    {{
                                      parseFloat(
                                        usuario.faturas[0].amount * 0.05
                                      )
                                        .toFixed(2)
                                        .replace(".", ",")
                                    }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small
                                    v-if="usuario.faturas.length"
                                  >R$
                                    {{
                                      parseFloat(
                                        usuario.faturas[0].amount -
                                          parseFloat(
                                            usuario.faturas[0].amount * 0.05
                                          )
                                      )
                                        .toFixed(2)
                                        .replace(".", ",")
                                    }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small v-if="usuario.faturas.length">{{
                                    new Date(
                                      usuario.faturas[0].created_at
                                    ).toLocaleDateString()
                                  }}</small>
                                </td>
                                <td
                                  v-if="usuario.faturas.length"
                                  class="text-center align-middle"
                                >
                                  <span
                                    v-if="usuario.faturas[0].status == 'paid'"
                                    class="btn btn-sm btn-success mr-2"
                                  >
                                    <small>FATURA PAGA</small>
                                  </span>
                                  <span
                                    v-else-if="
                                      usuario.faturas[0].status == 'pending'
                                    "
                                    class="btn btn-sm btn-success mr-2"
                                  >
                                    <small>Pendente</small>
                                  </span>
                                  <span
                                    v-else-if="
                                      usuario.faturas[0].status == 'canceled'
                                    "
                                    class="btn btn-sm btn-danger mr-2"
                                  >
                                    <small>CANCELADA</small>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        v-else
                        class="row"
                      >
                        <div class="col-12 table-responsive mt-2">
                          <div class="row">
                            <div class="col-6 table-responsive mt-2">
                              <h5>
                                Gateway de pagamento:
                                <img
                                  v-lazy="imageGerencianet"
                                  rel="Gerencianet"
                                  class="img-fluid"
                                  style="max-height: 50px"
                                >
                              </h5>
                            </div>
                            <div
                              class="col-6 fd-app-hierarquia-plataformas-body-search"
                            >
                              <autocomplete
                                placeholder="Pesquisa"
                                input-class="p-3"
                                :results-display="formattedDisplay"
                                :source="fastUsuarios"
                                @selected="destacaUsuario"
                              />
                            </div>
                          </div>

                          <div role="tablist">
                            <b-card
                              v-for="usuario in fastUsuarios"
                              :key="usuario.id_usuario"
                              no-body
                              class="mb-1"
                            >
                              <div
                                :fast-nome-usuario="
                                  usuario.nome_usuario + ' - ' + usuario.email
                                "
                              />
                              <b-card-header
                                header-tag="header"
                                class="p-1"
                                role="tab"
                              >
                                <b-button
                                  v-if="usuario.carnet_id"
                                  v-b-toggle.accordion-1
                                  block
                                  variant="info"
                                >
                                  Nome: {{ usuario.nome_usuario }} | e-mail:
                                  {{ usuario.email }} | carnê:
                                  {{ usuario.carnet_id }}
                                </b-button>
                              </b-card-header>
                              <b-collapse
                                id="accordion-1"
                                accordion="my-accordion"
                                role="tabpanel"
                              >
                                <div>
                                  <table class="table table-sm table-striped">
                                    <thead class="thead-light">
                                      <tr>
                                        <th>
                                          <small
                                            class="font-weight-bold"
                                          >#</small>
                                        </th>
                                        <th>
                                          <small
                                            class="font-weight-bold"
                                          >Vencimento</small>
                                        </th>
                                        <th>
                                          <small
                                            class="font-weight-bold"
                                          >Parcela</small>
                                        </th>
                                        <th>
                                          <small
                                            class="font-weight-bold"
                                          >Status</small>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="fatura2 in usuario.faturas"
                                        :key="fatura2.charge_id"
                                      >
                                        <td class="align-middle">
                                          <small>{{ fatura2.charge_id }}</small>
                                        </td>
                                        <td class="align-middle">
                                          <small>{{
                                            new Date(
                                              fatura2.expire_at
                                            ).toLocaleDateString()
                                          }}</small>
                                        </td>
                                        <td class="align-middle">
                                          <small>{{ fatura2.parcel }}</small>
                                        </td>
                                        <td class="align-middle">
                                          <span
                                            v-if="fatura2.status == 'settled'"
                                            class="btn btn-lg btn-success"
                                          >
                                            <small>
                                              FATURA PAGA
                                              <b-icon
                                                icon="hand-thumbs-up"
                                                animation="pulse"
                                                aria-hidden="true"
                                              />
                                            </small>
                                          </span>
                                          <span
                                            v-else-if="
                                              fatura2.status == 'waiting'
                                            "
                                          >
                                            <a
                                              :href="fatura2.url"
                                              class="text-white"
                                              target="_blank"
                                            >
                                              <b-button
                                                variant="primary"
                                                class="btn btn-lg btn-success"
                                              >
                                                Pendente&nbsp;
                                                <b-icon
                                                  icon="search"
                                                  animation="pulse"
                                                  aria-hidden="true"
                                                />
                                              </b-button>
                                            </a>
                                          </span>
                                          <span
                                            v-else-if="
                                              fatura2.status == 'canceled'
                                            "
                                            class="btn btn-lg btn-danger"
                                          >
                                            <small>
                                              CANCELADA
                                              <b-icon
                                                icon="hand-thumbs-down"
                                                animation="pulse"
                                                aria-hidden="true"
                                              />
                                            </small>
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </b-collapse>
                              <b-button
                                v-if="!usuario.carnet_id"
                                v-b-modal.modal-tall
                                block
                                variant="danger"
                                @click="sendInfo(usuario)"
                              >
                                Nome: {{ usuario.nome_usuario }} | ALUNO SEM
                                CARNÊ
                              </b-button>
                            </b-card>
                            <b-modal
                              id="modal-tall"
                              title="Criando Carnê"
                              hide-footer
                            >
                              <b-form
                                v-if="show"
                                @submit="onSubmit"
                                @reset="onReset"
                              >
                                <b-form-group
                                  id="input-group-0"
                                  label="NOME DO CURSO:"
                                  label-for="input-0"
                                >
                                  <b-form-input
                                    id="input-0"
                                    v-model="form.items[0].name"
                                    type="text"
                                    required
                                    placeholder="NOME DO CURSO"
                                  />
                                </b-form-group>
                                <b-form-group
                                  id="input-group-1"
                                  label="CPF:"
                                  label-for="input-1"
                                  description="Nunca comparilharemos as informações com nínguém"
                                >
                                  <b-form-input
                                    id="input-1"
                                    v-model="form.customer.cpf"
                                    v-mask="'###.###.###-##'"
                                    type="text"
                                    required
                                    placeholder="CPF"
                                  />
                                </b-form-group>

                                <b-form-group
                                  id="input-group-2"
                                  label="Nome :"
                                  label-for="input-2"
                                >
                                  <b-form-input
                                    id="input-2"
                                    v-model="form.customer.name"
                                    required
                                    placeholder="Nome e Sobrenome"
                                  />
                                </b-form-group>
                                <b-form-group
                                  id="input-group-7"
                                  label="E-mail :"
                                  label-for="input-7"
                                >
                                  <b-form-input
                                    id="input-7"
                                    v-model="form.customer.email"
                                    required
                                    placeholder="E-mail"
                                  />
                                </b-form-group>
                                <b-form-group
                                  id="input-group-3"
                                  label="Telefone :"
                                  label-for="input-3"
                                >
                                  <b-form-input
                                    id="input-3"
                                    v-model="form.customer.phone_number"
                                    v-mask="'(##)9.####-####'"
                                    required
                                    placeholder="Telefone"
                                  />
                                </b-form-group>

                                <b-form-group
                                  id="input-group-4"
                                  label="Valor da parcela:"
                                  label-for="input-4"
                                >
                                  <b-form-input
                                    id="input-4"
                                    v-model.number="form.items[0].value"
                                    required
                                    placeholder="R$"
                                    type="number"
                                  />
                                </b-form-group>
                                <b-form-group
                                  id="input-group-5"
                                  label="Parcelas:"
                                  label-for="input-5"
                                >
                                  <b-form-select
                                    id="input-5"
                                    v-model="form.repeats"
                                    :options="parcelas"
                                    required
                                  />
                                </b-form-group>
                                <b-form-group
                                  id="input-group-6"
                                  label="Primeiro Vencimento:"
                                  label-for="input-6"
                                >
                                  <b-form-input
                                    id="input-6"
                                    v-model="form.expire_at"
                                    required
                                    placeholder="R$"
                                    type="date"
                                  />
                                </b-form-group>
                                <vue-button-spinner
                                  :is-loading="isLoading"
                                  :disabled="isLoading"
                                  :status="status"
                                  type="submit"
                                  class="btn btn-sucess"
                                >
                                  <span>Criar Carnê</span>
                                </vue-button-spinner>
                                <b-button
                                  type="reset"
                                  variant="danger"
                                >
                                  Reset
                                </b-button>
                              </b-form>
                              <!-- <b-card class="mt-3" header="Form Data Result">
                                <pre class="m-0">{{ form }}</pre>
                              </b-card>-->
                            </b-modal>
                          </div>
                        </div>
                      </div>
                    </tab>
                  </tabs>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' + $route.params.id_plataforma + '/admin'                    
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <modal
      name="modalCriarLojaPromo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Nova promoção</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarLojaPromo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nome</label>
              <input
                v-model="fastLojaPromoNovo.nome_promo"
                type="text"
                class="form-control"
                maxlength="60"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-8 mb-2">
              <label>Descrição</label>
              <input
                v-model="fastLojaPromoNovo.descricao_promo"
                type="text"
                class="form-control"
                maxlength="255"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Valor à vista</label>
              <input
                v-model="fastLojaPromoNovo.valor_avista"
                type="number"
                step=".01"
                value
                class="form-control form-control-lg"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Valor parcelado no cartão</label>
              <input
                v-model="fastLojaPromoNovo.valor_parcelado"
                type="number"
                step=".01"
                value
                class="form-control form-control-lg"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Limite das parcelas</label>
              <input
                v-model="fastLojaPromoNovo.limite_parcelas"
                type="number"
                max="12"
                min="1"
                class="form-control form-control-lg"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarFastLojaPromo()"
                >
                  Criar promoção
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarPromo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar promoção</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarPromo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nome</label>
              <input
                v-model="fastLojaPromoEditar.nome_promo"
                type="text"
                class="form-control"
                maxlength="60"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-8 mb-2">
              <label>Descrição</label>
              <input
                v-model="fastLojaPromoEditar.descricao_promo"
                type="text"
                class="form-control"
                maxlength="255"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Valor à vista</label>
              <input
                v-model="fastLojaPromoEditar.valor_avista"
                type="number"
                step=".01"
                value
                class="form-control form-control-lg"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Valor parcelado no cartão</label>
              <input
                v-model="fastLojaPromoEditar.valor_parcelado"
                type="number"
                step=".01"
                value
                class="form-control form-control-lg"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Limite das parcelas</label>
              <input
                v-model="fastLojaPromoEditar.limite_parcelas"
                type="number"
                max="12"
                min="1"
                class="form-control form-control-lg"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="atualizarFastLojaPromo()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCursosPromo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Cursos da promoção</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCursosPromo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mb-2 text-right">
              <span
                class="btn btn-sm btn-success text-white pt-0 pb-0"
                @click="showModal('modalMeusCursos', 'modalCursosPromo')"
              >
                <small>Adicionar cursos</small>
              </span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">
                      Id
                    </th>
                    <th scope="col">
                      Curso
                    </th>
                    <th scope="col">
                      Carga horária
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Ação
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastLojaPromoEditar.cursos.length">
                  <tr
                    v-for="curso in fastLojaPromoEditar.cursos"
                    :key="curso.id_curso"
                  >
                    <td class="align-middle">
                      #{{ curso.id_curso }}
                    </td>
                    <td class="align-middle">
                      <a
                        target="_blank"
                        :href="
                          '/plataforma/' +
                            $route.params.id_plataforma +
                            '/admin/curso/' +
                            curso.id_curso
                        "
                      >
                      <span v-if="curso.nome_curso">{{ curso.nome_curso }}</span>
                      <span v-else>{{ curso.nome_curso_mae }}</span>
                      </a>
                    </td>
                    <td class="align-middle text-center">
                      {{ curso.carga_horaria }}h
                    </td>
                    <td class="align-middle text-center">
                      <span
                        class="btn btn-sm btn-danger text-white pt-0 pb-0"
                        @click="removeCursoPromo(curso)"
                      >
                        <small>- Remover curso</small>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhuma curso encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalMeusCursos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>
            Cursos disponíveis para venda ({{ fastMeusCursosFiltro.length }})
          </h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalMeusCursos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-center">
              <h6>Lista de cursos</h6>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
                @keyup="filtraCursos"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">
                      Id
                    </th>
                    <th scope="col">
                      Curso
                    </th>
                    <th scope="col">
                      Carga horária
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Ação
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfMeusCursos.length">
                  <tr
                    v-for="curso in pageOfMeusCursos"
                    :key="curso.id_curso"
                  >
                    <td class="align-middle">
                      #{{ curso.id_curso }}
                    </td>
                    <td class="align-middle">
                      <a
                        target="_blank"
                        :href="
                          '/plataforma/' +
                            $route.params.id_plataforma +
                            '/admin/curso/' +
                            curso.id_curso
                        "
                      >
                        <span v-if="curso.nome_curso">{{ curso.nome_curso }}</span>
                        <span v-else>{{ curso.nome_curso_mae }}</span>
                      </a>
                    </td>
                    <td class="align-middle text-center">
                      {{ curso.carga_horaria }}h
                    </td>
                    <td class="align-middle text-center">
                      <span
                        class="btn btn-sm btn-success text-white pt-0 pb-0"
                        @click="adicionarCursoPromo(curso)"
                      >
                        <small>+ Curso</small>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhuma curso encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="fastMeusCursosFiltro"
                @changePage="pageOfMeusCursos = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalRemoverPromo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Excluir promoção?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalRemoverPromo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="removerPromo()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import imageGerencianet from "@/assets/images/logos_gateway/2.png";
import imagefastEad from "@/assets/images/logo_horizontal.png";
import Autocomplete from "vuejs-auto-complete";
import VueButtonSpinner from "vue-button-spinner";
import Pagination from "../components/Pagination";
//import ImageSiteFormEffect01 from "@/assets/images/form-effect-01.png";

export default {
  name: "EventsForm",
  name: "HomeInternoAdminFinanceiro",
  components: {
    Autocomplete,
    VueButtonSpinner,
    Pagination,
  },
  mixins: [methods],
  data: function () {
    return {
      isLoading: false,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      status: "",
      parcelas: [
        { text: "Selecione o número de parcelas", value: null },
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
      ],
      form: {
        items: [
          {
            name: "",
            value: "",
            amount: 1,
          },
        ],
        customer: {
          name: "",
          cpf: "",
          phone_number: "",
          email: "",
        },
        expire_at: "",
        repeats: "",
        split_items: false,
      },

      show: true,
      isHovered: false,
      imageGerencianet,
      imagefastEad,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Loja promo
      fastLojaPromo: [],
      fastLojaPromoNovo: {
        nome_promo: "",
        descricao_promo: "",
        id_plataforma: 0,
        valor_avista: "",
        valor_parcelado: "",
        limite_parcelas: "",
      },
      fastLojaPromoEditar: {
        id_promo: "",
        nome_promo: "",
        descricao_promo: "",
        id_plataforma: 0,
        valor_avista: "",
        valor_parcelado: "",
        limite_parcelas: "",
        cursos: [],
      },
      fastLojaPromoExcluir: {
        nome_promo: "",
        descricao_promo: "",
        id_plataforma: 0,
        valor_avista: "",
        valor_parcelado: "",
        limite_parcelas: "",
      },
      // Cursos
      fastMeusCursos: [],
      pageOfMeusCursos: [],
      fastMeusCursosFiltro: [],
      // Usuários e fatures
      fastUsuarios: [],
      // Plataforma parametros
      fastGateway: "",
      fastPlataformaParametro: [],
      fastPlataformaFaturas: [],
      fastUsuarioSelecionado: [],
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getFastLojaPromo();
          this.getFastUsuarios();
          this.getFastPlataformaParametro();
          this.getFastCursos();
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    sendInfo(usuario) {
      // console.log(usuario);
      this.fastUsuarioSelecionado = usuario;
      //console.log(this.fastUsuarioSelecionado);

      this.form.customer.name = usuario.nome_usuario;
      this.form.customer.email = usuario.email;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.isLoading = true;
      this.postCarneGerencianet(
        this.fastUsuarioSelecionado.id_usuario,
        this.$route.params.id_plataforma,
        JSON.stringify(this.form)
      );
      // alert(this.fastUsuarioSelecionado.id_usuario);
      // alert(this.$route.params.id_plataforma);
      // alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.items[0].name = "";
      this.form.items[0].value = 0;
      this.form.customer.name = "";
      this.form.customer.email = "";
      this.form.customer.cpf = "";
      this.form.customer.phone_number = "";
      this.form.expire_at = "";
      this.form.repeats = null;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    handleHover(hovered) {
      this.isHovered = hovered;
    },
    formattedDisplay(usuario) {
      if (usuario.nome_usuario == null) usuario.nome_usuario = "s/ nome";
      return usuario.nome_usuario + " - " + usuario.email;
    },
    //CRIA CARNÊ
    async postCarneGerencianet(id_usuario, id_plataforma, JsonCarne) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_gateway_pagto/cria_carnes_gerencianet?id_usuario=" +
            id_usuario +
            "&id_plataforma=" +
            this.$route.params.id_plataforma +
            "&JsonCarne=" +
            JsonCarne,
          this.fastAjaxOptions("POST")
        );
        let json = await resp.json();
        // console.log(json);

        this.$nextTick(() => {
          this.$bvModal.hide("modal-tall");
          this.getFastUsuarios();
          this.makeToast("success", json);
        });
      } catch (e) {
        console.log("Erro", e);
        this.makeToast("danger", e.message);
      }
    },
    // Loja promo
    async getFastLojaPromo() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_loja_promo/lista_plataforma?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastLojaPromo = obj;
        } else {
          this.fastLojaPromo = [];
        }
        console.log("fastLojaPromo", this.fastLojaPromo);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async criarFastLojaPromo() {
      let erros = [];
      if (this.fastLojaPromoNovo.nome_promo == "")
        erros.push("O nome não pode ficar em branco");
      if (this.fastLojaPromoNovo.descricao_promo == "")
        erros.push("A descrição não pode ficar em branco");
      if (this.fastLojaPromoNovo.valor_avista == "")
        erros.push("O valor à vista não pode ficar em branco");
      if (this.fastLojaPromoNovo.valor_parcelado == "")
        erros.push("O valor parcelado não pode ficar em branco");
      if (this.fastLojaPromoNovo.limite_parcelas == "")
        erros.push("O limite de parcelas não pode ficar em branco");

      if (erros.length) {
        erros.forEach((e) => {
          this.$toast.open({
            message: e,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
        });
      } else {
        const fast_plataforma_loja_promo = {
          nome_promo: this.fastLojaPromoNovo.nome_promo,
          descricao_promo: this.fastLojaPromoNovo.descricao_promo,
          id_plataforma: this.$route.params.id_plataforma,
          valor_avista: this.fastLojaPromoNovo.valor_avista,
          valor_parcelado: this.fastLojaPromoNovo.valor_parcelado,
          limite_parcelas: this.fastLojaPromoNovo.limite_parcelas,
        };
        this.$store.state.fastCarregando = true;
        this.promiseInserirFastApi(
          fast_plataforma_loja_promo,
          "fast_plataforma_loja_promo"
        )
          .then((e) => {
            console.log("promiseInserirFastApi", e);
            this.getFastLojaPromo();
            this.fastLojaPromoNovo = {
              nome_promo: "",
              descricao_promo: "",
              id_plataforma: 0,
              valor_avista: "",
              valor_parcelado: "",
              limite_parcelas: "",
            };
            this.hideModal("modalCriarLojaPromo");
            this.$store.state.fastCarregando = false;
          })
          .catch((e) => {
            console.log(e);
            this.$store.state.fastCarregando = false;
          });
      }
    },
    exibeModalCursosPromo(promo) {
      this.fastLojaPromoEditar.id_promo = promo.id_promo;
      this.promiseGetFastApi(
        "api/fast_plataforma_loja_promo_cursos/lista",
        `id_promo=${promo.id_promo}&id_plataforma=${this.$route.params.id_plataforma}`
      )
        .then((e) => {
          console.log("exibeModalCursosPromo", e);
          this.fastLojaPromoEditar.cursos = e;
          this.showModal("modalCursosPromo");
        })
        .catch((e) => {
          console.log("exibeModalCursosPromo", e);
        });
    },
    async adicionarCursoPromo(curso) {
      const fast_plataforma_loja_promo_cursos = {
        id_promo: this.fastLojaPromoEditar.id_promo,
        id_curso: curso.id_curso,
      };
      this.$store.state.fastCarregando = true;
      this.promiseInserirFastApi(
        fast_plataforma_loja_promo_cursos,
        "fast_plataforma_loja_promo_cursos"
      )
        .then((e) => {
          console.log("adicionarCursoPromo", e);
          this.$store.state.fastCarregando = false;
          this.promiseGetFastApi(
            "api/fast_plataforma_loja_promo_cursos/lista",
            `id_promo=${this.fastLojaPromoEditar.id_promo}&id_plataforma=${this.$route.params.id_plataforma}`
          )
            .then((e) => {
              console.log("exibeModalCursosPromo", e);
              this.fastLojaPromoEditar.cursos = e;
              this.showModal("modalCursosPromo", "modalMeusCursos");
              this.getFastLojaPromo();
            })
            .catch((e) => {
              console.log("exibeModalCursosPromo", e);
            });
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.fastCarregando = false;
        });
    },
    async getFastCursos() {
      this.fastMeusCursos = [];
      this.fastMeusCursosFiltro = [];
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_plataforma?id_usuario=0" +
            "&id_plataforma=" +
            this.retornaSessao(settings.fastSessaoPlataforma).id_plataforma +
            "&id_curso=",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        console.log("getFastCursos", json);
        let objPlataforma = Array.from(json);

        if (objPlataforma.length > 0) {
          objPlataforma.forEach((e) => {
            if (e.id_produto_vindi) {
              this.fastMeusCursos.push(e);
              this.fastMeusCursosFiltro.push(e);
            }
          });
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtraCursos(e) {
      let text = e.target.value;
      this.fastMeusCursosFiltro = this.fastMeusCursos.filter((e) => {
        if (e.nome_curso) return e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
        else
          return e.nome_curso_mae.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
      });
    },
    async removeCursoPromo(curso) {
      const fast_plataforma_loja_promo_cursos = {
        id_promo: this.fastLojaPromoEditar.id_promo,
        id_curso: curso.id_curso,
      };
      this.$store.state.fastCarregando = true;
      this.promiseExcluirFastApi(
        fast_plataforma_loja_promo_cursos,
        "fast_plataforma_loja_promo_cursos"
      )
        .then((e) => {
          console.log("adicionarCursoPromo", e);
          this.$store.state.fastCarregando = false;
          this.promiseGetFastApi(
            "api/fast_plataforma_loja_promo_cursos/lista",
            `id_promo=${this.fastLojaPromoEditar.id_promo}&id_plataforma=${this.$route.params.id_plataforma}`
          )
            .then((e) => {
              console.log("exibeModalCursosPromo", e);
              this.fastLojaPromoEditar.cursos = e;
              this.showModal("modalCursosPromo", "modalMeusCursos");
              this.getFastLojaPromo();
            })
            .catch((e) => {
              console.log("exibeModalCursosPromo", e);
            });
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.fastCarregando = false;
        });
    },
    exibeModalRemoverPromo(promo) {
      this.fastLojaPromoExcluir = promo;
      this.showModal("modalRemoverPromo");
    },
    async removerPromo() {
      const fast_plataforma_loja_promo = {
        id_promo: this.fastLojaPromoExcluir.id_promo,
        id_plataforma: this.fastLojaPromoExcluir.id_plataforma,
      };
      this.$store.state.fastCarregando = true;
      this.promiseExcluirFastApi(
        fast_plataforma_loja_promo,
        "fast_plataforma_loja_promo"
      )
        .then((e) => {
          this.$store.state.fastCarregando = false;
          this.hideModal("modalRemoverPromo");
          this.getFastLojaPromo();
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.fastCarregando = false;
        });
    },
    exibeModalEditarPromo(promo) {
      this.fastLojaPromoEditar = {
        id_promo: promo.id_promo,
        nome_promo: promo.nome_promo,
        descricao_promo: promo.descricao_promo,
        id_plataforma: this.$route.params.id_plataforma,
        valor_avista: promo.valor_avista,
        valor_parcelado: promo.valor_parcelado,
        limite_parcelas: promo.limite_parcelas,
        cursos: [],
      };
      this.showModal("modalEditarPromo");
    },
    async atualizarFastLojaPromo() {
      let erros = [];
      if (this.fastLojaPromoEditar.nome_promo == "")
        erros.push("O nome não pode ficar em branco");
      if (this.fastLojaPromoEditar.descricao_promo == "")
        erros.push("A descrição não pode ficar em branco");
      if (this.fastLojaPromoEditar.valor_avista == "")
        erros.push("O valor à vista não pode ficar em branco");
      if (this.fastLojaPromoEditar.valor_parcelado == "")
        erros.push("O valor parcelado não pode ficar em branco");
      if (this.fastLojaPromoEditar.limite_parcelas == "")
        erros.push("O limite de parcelas não pode ficar em branco");

      if (erros.length) {
        erros.forEach((e) => {
          this.$toast.open({
            message: e,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
        });
      } else {
        const fast_plataforma_loja_promo = {
          id_promo: this.fastLojaPromoEditar.id_promo,
          nome_promo: this.fastLojaPromoEditar.nome_promo,
          descricao_promo: this.fastLojaPromoEditar.descricao_promo,
          id_plataforma: this.$route.params.id_plataforma,
          valor_avista: this.fastLojaPromoEditar.valor_avista,
          valor_parcelado: this.fastLojaPromoEditar.valor_parcelado,
          limite_parcelas: this.fastLojaPromoEditar.limite_parcelas,
        };
        this.$store.state.fastCarregando = true;
        this.promiseAtualizarFastApi(
          fast_plataforma_loja_promo,
          "fast_plataforma_loja_promo"
        )
          .then((e) => {
            this.getFastLojaPromo();
            this.fastLojaPromoEditar = {
              id_promo: "",
              nome_promo: "",
              descricao_promo: "",
              id_plataforma: 0,
              valor_avista: "",
              valor_parcelado: "",
              limite_parcelas: "",
              cursos: [],
            };
            this.hideModal("modalEditarPromo");
            this.$store.state.fastCarregando = false;
          })
          .catch((e) => {
            console.log(e);
            this.$store.state.fastCarregando = false;
          });
      }
    },
    // Usuários e faturas
    async getFastUsuarios() {
      let param = `id_plataforma=${this.$route.params.id_plataforma}&administrador=&professor=&aluno=S&tesouraria=&secretario=&responsavel=`
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_usuario/lista_usuarios_plataforma?" + param,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        //console.log(obj);
        if (obj.length) {
          let novoArray = [];
          obj.forEach((u, index) => {
            u.faturas = [];
            u.carnet_id = "";
            if (u.id_usuario) novoArray.push(u);

            if (u.id_gateway == "2") {
              this.getFastUsuarioFaturasGerencianet(u.id_usuario);
            } else {
              if (u.id_pessoa) {
                this.getFastUsuarioFaturas(u.id_pessoa);
              }
            }
          });
          this.fastGateway = obj[0].id_gateway;
          this.fastUsuarios = novoArray;
        } else {
          this.fastUsuarios = [];
        }
        //console.log("fastUsuarios", this.fastUsuarios);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Plataforma parâmetro
    async getFastPlataformaParametro() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_parametro/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataformaParametro = obj[0];
          //console.log(this.fastPlataformaParametro.id_pessoa);
          if (this.fastPlataformaParametro.id_pessoa)
            this.getFastPlataformaFaturas(
              this.fastPlataformaParametro.id_pessoa
            );
        } else {
          this.fastPlataformaParametro = [];
        }
        //console.log("fastPlataformaParametros", this.fastPlataformaParametro);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastPlataformaFaturas(id_pessoa) {
      // console.log(id_pessoa);
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_usuario/lista_faturas?id_pessoa=" +
            id_pessoa,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        //console.log(json);

        let obj = JSON.parse(json);
        //console.log(obj);
        if (obj.bills) {
          this.fastPlataformaFaturas = obj.bills;
        } else {
          this.fastPlataformaFaturas = [];
        }

        //console.log("fastPlataformaFaturas", this.fastPlataformaFaturas);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastUsuarioFaturas(id_pessoa) {
      try {
        //console.log(id_pessoa);

        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_usuario/lista_faturas?id_pessoa=" +
            id_pessoa,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();

        let obj = JSON.parse(json);
        //console.log(obj);

        if (obj.bills) {
          this.fastUsuarios.forEach((u, index) => {
            if (u.id_pessoa == id_pessoa)
              this.fastUsuarios[index].faturas = obj.bills;
          });
        } else {
          let novoArray = [];
          this.fastUsuarios.forEach((u, index) => {
            if (u.id_pessoa != id_pessoa) novoArray.push(u);
          });
          this.fastUsuarios = novoArray;
        }
        this.$store.state.fastCarregando = false;
        //console.log("fastUsuarios com faturas", this.fastUsuarios);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    destacaUsuario(nome_usuario) {
      console.log("Destacando " + nome_usuario.display);
      Array.from(document.querySelectorAll("[fast-nome-usuario]")).forEach(
        (e) => {
          e.classList.remove("bg-warning");
          e.parentNode.classList.remove("collapse");
        }
      );
      Array.from(
        document.querySelectorAll("[fd-data-toggle='collapse']")
      ).forEach((e) => {
        if (e.querySelectorAll(".collapsed").length > 0) e.click();
      });

      let divUsuario = document.querySelector(
        '[fast-nome-usuario="' + nome_usuario.display + '"]'
      );
      //console.log(divUsuario);
      if (divUsuario) {
        divUsuario.classList.add("bg-warning");
        divUsuario.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    },
    async getFastUsuarioFaturasGerencianet(id_usuario) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_gateway_pagto/lista_carnes_gerencianet?id_usuario=" +
            id_usuario +
            "&id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        //console.log(json);
        if (json !== "não foi encontrado o carnê gerencianet") {
          let obj = JSON.parse(json);
          //console.log(obj);
          if (obj.carnet_id) {
            this.fastUsuarios.forEach((u, index) => {
              if (u.id_usuario == id_usuario) {
                this.fastUsuarios[index].carnet_id = obj.carnet_id;
                this.fastUsuarios[index].faturas = obj.charges;
              }
            });
          }
        } else {
          this.fastUsuarios.forEach((u, index) => {
            if (u.id_usuario == id_usuario)
              this.fastUsuarios[index].id_usuario = u.id_usuario;
          });
        }
        this.$store.state.fastCarregando = false;
        //console.log("fastUsuarios com faturas", this.fastUsuarios);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    somaProdutosFatura(bill_items) {
      let soma = 0;
      bill_items.forEach((e) => {
        soma += parseFloat(e.amount);
      });
      return soma.toFixed(2);
    },
  },
};
</script>

<style scope>
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
